import { token } from '~/utilities/auth.helper';
import type { App } from 'contracts/mod';

const BILLING_URL_V1 = `${process.env.APP_LAYER_URL}/billing/v1` as const;

/**
 * Retrieves the current invoice config for the health plan or subplan
 * @param {GetInvoiceConfigRequest} invoiceConfigRequest
 * @returns {GetInvoiceConfigResponse}
 */
export async function getInvoiceConfig({
  healthPlanId,
  healthSubPlanId
}: App.Billing.V1.HealthPlans.GetInvoiceConfigRequest) {
  const response = await fetch(
    `${BILLING_URL_V1}/health-plans/invoices/config/list?healthPlanId=${healthPlanId}${healthSubPlanId ? `&healthSubPlanId=${healthSubPlanId}` : ''}`,
    {
      headers: { Authorization: `Bearer ${token()}` }
    }
  );

  return response.json() as Promise<App.Billing.V1.HealthPlans.GetInvoiceConfigResponse>;
}

/**
 * Insert or Update an invoice config for the provided health plan or subplan
 * @param {UpsertInvoiceConfigRequest} upsertInvoiceConfigRequest
 * @returns {UpsertInvoiceConfigResponse}
 */
export async function upsertInvoiceConfig(
  upsertInvoiceConfigRequest: App.Billing.V1.HealthPlans.UpsertInvoiceConfigRequest
) {
  const response = await fetch(`${BILLING_URL_V1}/health-plans/invoices/config`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${token()}` },
    body: JSON.stringify(upsertInvoiceConfigRequest)
  });

  return response.json() as App.Billing.V1.HealthPlans.UpsertInvoiceConfigResponse;
}
