import { LARAVEL_APP_URL } from '~/constants';
import { manualRollbarDebug } from '~/services/rollbar.service';
import { globalDebugOnlyUserData } from '~/Modules/user';
import { analyticsService } from '~/analytics';

const readCookie = name => {
  const nameEQ = `${name}=`;

  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return null;
};

/** The main auth token
 *
 * We want this be be freshly retrieved before every use because there's this edge case where a user
 * can tunnel in via a completely different tab, use that all day, and then return to the original tab and
 * the token would be expired and kick them out of the system unexpectedly. This is an attempt to prevent that
 * and resolve the UHC unexpected logout issues of mid-2024.
 *
 * Performance note: As of 2024-06-11, this function takes about 0.1-0.5 milliseconds to run before
 * each and every API call, so impact is minimal.
 */
export const token = () => readCookie(`${process.env.REACT_APP_ENVIRONMENT}_auth_token`);

export const adminToken = readCookie('super_admin_logged_in');
export const maToken = readCookie('multi_account_logged_in');

/** Returns a fresh request config with the up-to-date, non-cached main auth token.
 */
export const baseRequestConfig = () => ({
  headers: {
    'Authorization': `Bearer ${token()}`,
    'Content-Type': `application/json`
  }
});

/** Returns a fresh request config with the up-to-date, non-cached main auth token
 * plus any additional params and header values you want to include.
 */
export const extendedRequestConfig = (
  additionalConfigParams = {},
  additionalHeaderParams = {}
) => ({
  headers: {
    ...baseRequestConfig().headers,
    ...additionalHeaderParams
  },
  ...additionalConfigParams
});

/**
 * Logs out the user from care by redirecting to the Laravel logout url.
 * The extensive/detailed rollbar and mixpanel tracking here is to help us identify the root cause
 * of unexpected logouts happening mid-2024 and should be removed once this is no longer an issue.
 */
export const logout = (
  trigger:
    | 'Logout Button'
    | 'User Idle'
    | 'Header Button'
    | 'Pusher'
    | 'Get User Error'
    | 'Get User Saga Error'
    | 'Session Check Error'
    | 'Extend Session Error',
  additonalRelevantData: Record<string, unknown> = {}
) => {
  const eventTriggerTime = Date.now();

  manualRollbarDebug(trigger, {
    eventTriggerTime,
    ...globalDebugOnlyUserData,
    ...additonalRelevantData
  });

  analyticsService
    .trackEvent('User Logout', {
      eventTriggerTime,
      eventSource: trigger,
      ...globalDebugOnlyUserData,
      ...additonalRelevantData
    })
    .then(() => {
      window.location.href = `${LARAVEL_APP_URL}/logout`;
    });
};
