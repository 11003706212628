import React, { useEffect, useMemo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '~/Modules';
import type { VenueStore, VenueRecord } from '~/Modules/venues/Venues.types';
import { getVenues } from '~/Modules/venues/thunks';

type VenueOption = {
  value: string;
  label: JSX.Element;
};

type EntranceOption = {
  value: VenueRecord;
  label: JSX.Element;
};

export type VenuesHookProps = [
  { [x: string]: VenueRecord[] },
  VenueOption[],
  (venueName: string, onHover: (entrance: string) => void) => EntranceOption[]
];

export default function useVenues(): VenuesHookProps {
  const dispatch = useAppDispatch();
  const venues = useAppSelector(state => state.venues?.venues);
  const status = useAppSelector(state => state.venues?.status);

  useEffect(() => {
    if (!status) {
      dispatch(getVenues());
    }
  }, [status, venues]);

  /**
   * Given the venues from the store, this function generates
   * a list of venue options with name and address
   * @param {Object} venues
   * @returns {VenueOption[]}
   */
  function generateVenueOptions(venues: VenueStore['venues']): VenueOption[] {
    if (!venues) return [];
    return Object.entries(venues).map(venue => {
      const [venueName, venueRecord] = venue;
      const venueLocation = venueRecord[0];

      return {
        value: venueName,
        label: (
          <span>
            <span className="font-bold">{venueName}: </span> {venueLocation.address}
          </span>
        )
      };
    });
  }

  /** Memoized so that we only create the options only when the store is updated */
  const venueOptions = useMemo(() => generateVenueOptions(venues), [venues]);

  /** Generates entrance options for the dropdown when a venue name is selected*/
  const genEntranceOptions = useCallback(
    (venueName: string, onHover: (entrance: string) => void): EntranceOption[] => {
      if (!venues) return [];

      return venues[venueName].map((entrance: VenueRecord) => ({
        value: entrance,
        label: (
          <div onMouseOver={() => onHover(entrance.name)}>
            <span className="font-bold">&#8627; {entrance.name}</span>
          </div>
        )
      }));
    },
    [venues]
  );

  return [venues, venueOptions, genEntranceOptions];
}
