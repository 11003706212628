import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _, { cloneDeep } from 'lodash-es';
import { getClassName } from '~/utilities/helperFunctions';
import LoadingComponent from '~/Pages/App/Components/LoadingComponent';
import BenefitsModal from './Components/BenefitsModal';
import SvgEdit from '~/Shared/Components/Svgs/SvgEdit';
import SvgDelete from '~/Shared/Components/Svgs/SvgDelete';
import {
  getCustomFields,
  postCustomField,
  deleteCustomField,
  getInvoiceConfig,
  upsertInvoiceConfig
} from '~/Modules/settings';
import { getBenefitsOverwrite, postBenefitsOverwrite } from '~/services/benefits.service';
import {
  getClaimSubmissionTypes,
  postHealthPlanClaimSubmissionType
} from '~/services/claimSubmission.service';
import BillingConfiguration, {
  BILLING_PARAMETERS
} from './Components/BillingConfiguration/BillingConfiguration';
import { isEmpty, isNumeric } from '@SRHealth/frontend-lib';

const TAB_CUSTOM_FIELDS = 'custom-fields';
const TAB_BENEFITS_OVERWRITE = 'benefits-overwrite';
const TAB_DEFAULT_CLAIM_SUBMISSIONS = 'default-claim-submissions';
const TAB_BILLING = 'billing';

const SUBTAB_FREEFORM = 'freeform';
const SUBTAB_VALIDATION = 'validation';
const SUBTAB_NOTES = 'notes';
const TYPE_MAPPING = {
  freeform: 'string',
  validation: 'select',
  notes: 'text'
};

const TYPE_NAMES = {
  freeform: 'Free Form',
  validation: 'Validation',
  notes: 'Notes'
};

class CustomFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showDelete: false,
      loading: false,
      editError: false,
      formValid: false,
      tab: TAB_CUSTOM_FIELDS,
      subtab: SUBTAB_FREEFORM,
      formData: {},
      benefitsOverwrite: [],
      claimSubmissionTypes: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.getCustomFields();
      this.props.getInvoiceConfig({ healthPlanId: this.props.user.healthPlan.id });
      getClaimSubmissionTypes().then(response => {
        this.setState({
          claimSubmissionTypes: response.data
        });
      });
      getBenefitsOverwrite().then(response => {
        this.setState({
          benefitsOverwrite: response.data,
          loading: false
        });
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.customFieldsLastLoad, prevProps.customFieldsLastLoad)) {
      this.setState({ loading: false, formData: {} });
    }
    if (
      !_.isEqual(
        this.props.billingParametersLastLoad,
        prevProps.billingParametersLastLoad
      )
    ) {
      this.setState({ loading: false, formData: {} });
    }
  }

  /**
   * Handle request to close modal
   * @param {SyntheticEvent} e React event object
   * @returns {undefined}
   */
  handleCloseModal = e => {
    e.stopPropagation();
    this.setState({
      showModal: false,
      formData: {}
    });
  };

  /**
   *Handles the closing of the delete modal
   * @param {SyntheticEvent} e  the click event
   * @returns {undefined}
   */
  handleCloseDelete = e => {
    e.stopPropagation();
    this.setState({ showDelete: false });
  };

  /**
   * Returns the count of custom fields per type
   * @memberof PlanCustomFields
   * @param {undefined} - Nothing
   * @returns {Number} The count of fields for the current selected type
   */
  typeCount = () => {
    let currentCount = 0;
    this.props.customFields.forEach(customField => {
      if (customField.field_type === TYPE_MAPPING[this.state.subtab]) {
        currentCount++;
      }
    });
    return currentCount;
  };

  /**
   * Opens the edit custom field modal
   *
   * @memberof PlanCustomFields
   * @param {SyntheticEvent} e The click event
   * @returns {undefined} -
   */
  handleOpenModal = e => {
    e.stopPropagation();
    if (this.state.subtab === SUBTAB_VALIDATION) {
      this.setState({
        showModal: true,
        formData: {
          selectors: ['']
        }
      });
    } else {
      this.setState({
        showModal: true,
        formValid: false
      });
    }
  };

  /**
   * Handles submitting of the edit modal
   * @memberof PlanCustomFields
   * @returns {undefined} -
   */
  handleSubmitModal = () => {
    const newData = _.cloneDeep(this.state.formData);
    this.props.postCustomField({
      ...newData,
      field_type: TYPE_MAPPING[this.state.subtab] //string, select, text
    });
    this.setState({
      formData: {},
      loading: true,
      showModal: false
    });
  };

  /**
   * Handles submitting of benefits overwrite
   * @memberof PlanBenefitsOverwrite
   * @returns {undefined} -
   */
  handleSubmitBenefitsOverwrite = () => {
    const roleIds = this.state.benefitsOverwrite
      .filter(benefit => benefit.selected === 1)
      .map(benefit => benefit.id);
    postBenefitsOverwrite(roleIds);
  };

  /**
   * Opens the delete confirmation modal
   * @param {Number} id - The custom field id to confirm deletion
   * @returns {Function} - a function to update state
   */
  showDelete = id => {
    return () => {
      this.setState({
        showDelete: id
      });
    };
  };

  /**
   * get all custom fields
   * @return {undefined} - return nothing
   */
  getCustomFields = () => {
    this.props.getCustomFields();
  };

  /**
   * Triggers an action to delete a custom field
   * @param {Number} id The custom field to delete
   * @returns {Function} - a function to update state and kick off delete action
   */
  deleteCustomField = id => {
    return () => {
      this.props.deleteCustomField({
        id
      });
      this.setState({ showDelete: false });
    };
  };

  /**
   * Triggers action to modify a custom field
   * @param {Number} fieldId - The id of the field to edit
   * @returns {Function} - A function that will update the state
   */
  editCustomField = fieldId => {
    return () => {
      const foundRecord = _.find(this.props.customFields, record => {
        return record.id === fieldId;
      });
      if (typeof foundRecord.selectors !== 'undefined') {
        foundRecord.selectors = foundRecord.selectors.map(selector => {
          return selector.label;
        });
      }
      this.setState({
        showModal: true,
        formData: foundRecord,
        formValid: true
      });
    };
  };

  /**
   * Handles switching tabs when a tab is clicked
   * @param {String} subtab The tab that was clicked
   * @returns {Function} - a function to change component state
   */
  handleSubTab = subtab => {
    return () => {
      this.setState({ subtab, formData: {} });
    };
  };

  /**
   * Handles switching tabs when a tab is clicked
   * @param {String} tab The tab that was clicked
   * @returns {Function} - a function to change component state
   */
  handleTab = tab => {
    return () => {
      this.setState({ tab, formData: {} });
    };
  };

  /**
   * Dynamically updates component state with live edits from the modal
   * @param {string} element The element to update
   * @returns {Function} The function that updates the state
   */
  formUpdate = element => {
    return e => {
      const newFormData = cloneDeep(this.state.formData);
      _.set(newFormData, element, e.target.value);
      if (element === 'label') {
        if (e.target.value.length === 0) {
          this.setState({
            formValid: false
          });
        } else {
          this.setState({
            formValid: true
          });
        }
      }
      this.setState({
        formData: newFormData
      });
    };
  };

  /**
   * Adds a blank option to form data
   * @returns {undefined}
   */
  addOption = () => {
    const newFormData = cloneDeep(this.state.formData);
    newFormData.selectors.push('');
    this.setState({
      formData: newFormData
    });
  };

  /**
   * Removes an option from form data
   * @param {Number} index - the id to remove
   * @returns {Function} Function to update component state
   */
  removeOption = index => {
    return () => {
      const newFormData = cloneDeep(this.state.formData);
      if (typeof index === 'undefined') {
        newFormData.selectors.pop();
      } else if (index === 'all') {
        newFormData.selectors = [];
      } else {
        delete newFormData.selectors[index];
      }
      const newSelectors = cloneDeep(newFormData.selectors);
      newFormData.selectors = [];
      newSelectors.forEach(selector => {
        //Reindex arr
        if (typeof selector !== 'undefined') {
          newFormData.selectors.push(selector);
        }
      });
      this.setState({
        formData: newFormData
      });
    };
  };

  benefitsOverwriteRoleChange = index => {
    return e => {
      const newBenefitsOverwrite = cloneDeep(this.state.benefitsOverwrite);
      if (e.target.checked) {
        newBenefitsOverwrite[index].selected = 1;
      } else {
        newBenefitsOverwrite[index].selected = 0;
      }

      this.setState({
        benefitsOverwrite: newBenefitsOverwrite
      });
    };
  };

  /**
   * Sets the currently selected submission type id in state
   * @param selectedId
   * @returns
   */
  claimSubmissionTypeChange = selectedId => {
    this.setState({
      claimSubmissionTypes: this.state.claimSubmissionTypes.map(c => ({
        ...c,
        selected: +selectedId === c.submission_type_id ? !c.selected : false
      }))
    });
  };

  /**
   * Submits the claim submission types in state to the api service
   * @param
   * @returns
   */
  handleSubmitClaimSubmissionType = () => {
    const claimSubmissionTypeId = this.state.claimSubmissionTypes.find(
      c => c.selected === true
    )?.submission_type_id;

    postHealthPlanClaimSubmissionType(claimSubmissionTypeId || null);
  };

  // #region Billing Configuration Methods

  /**
   * Submits the invoice configuration for the current health plan
   * @returns {void}
   */
  handleInvoiceConfigParametersSubmitModal = () => {
    const newData = { ...this.state.formData };
    this.props.upsertInvoiceConfig({
      healthPlanId: this.props.user.healthPlan.id,
      [newData['label']]: newData['value']
    });
    this.setState({
      formData: {},
      loading: true,
      showModal: false
    });
  };

  /**
   * Sets the new state for a given invoice parameter
   * @param {string} parameterName Parameter name to be edited
   * @param {string|number} value New parameter value
   * @returns {MouseEventHandler}
   */
  editBillingParameter = (parameterName, value) => {
    return () => {
      this.setState({
        showModal: true,
        formData: { label: parameterName, value },
        formValid: true
      });
    };
  };

  /**
   * Sets the new state for a given invoice parameter
   * @param {string} formProperty Property to be updated
   * @param {string} fieldName Field name to be updated in form
   * @returns {ChangeEventHandler}
   */
  updateBillingForm = (formProperty, fieldName) => {
    return e => {
      const parameterValue = e.target.value;
      const newFormData = cloneDeep(this.state.formData);
      _.set(
        newFormData,
        formProperty,
        isNumeric(parameterValue) ? Number(parameterValue) : parameterValue
      );
      this.setState({
        formData: newFormData,
        formValid: this.isBillingParameterValid(fieldName, parameterValue)
      });
    };
  };

  /**
   * Validates a given invoice parameter
   * @param {string} fieldName Field name to validate
   * @param {number} value Given value to be validated
   * @returns {bool}
   */
  isBillingParameterValid = (fieldName, value) => {
    switch (fieldName) {
      case BILLING_PARAMETERS.periodicity:
        return true;
      case BILLING_PARAMETERS.submissionDeadline:
        return !isEmpty(value) && Number(value) >= 0 && Number(value) <= 365;
      case BILLING_PARAMETERS.reviewPeriod:
        return !isEmpty(value) && Number(value) >= 0 && Number(value) <= 31;
      default:
        return false;
    }
  };

  // #endregion

  render() {
    let recordsListed = 0;
    if (this.state.loading || this.props.customFields === null) {
      return <LoadingComponent />;
    }
    return (
      <div className="SettingsWrapper">
        <div className="settings">
          <ul className="tabs">
            <li
              className={getClassName({
                planTab: true,
                selected: this.state.tab === TAB_CUSTOM_FIELDS
              })}
              onClick={this.handleTab(TAB_CUSTOM_FIELDS)}
            >
              Custom Fields
            </li>
            <li
              className={getClassName({
                planTab: true,
                selected: this.state.tab === TAB_BENEFITS_OVERWRITE
              })}
              onClick={this.handleTab(TAB_BENEFITS_OVERWRITE)}
            >
              Benefits Overwrite
            </li>
            <li
              className={getClassName({
                planTab: true,
                selected: this.state.tab === TAB_DEFAULT_CLAIM_SUBMISSIONS
              })}
              onClick={this.handleTab(TAB_DEFAULT_CLAIM_SUBMISSIONS)}
            >
              Default Claim Submissions
            </li>
            <li
              className={getClassName({
                planTab: true,
                selected: this.state.tab === TAB_BILLING
              })}
              onClick={this.handleTab(TAB_BILLING)}
            >
              Billing
            </li>
          </ul>
        </div>
        <div className="CustomFields">
          {
            {
              [TAB_CUSTOM_FIELDS]: (
                <>
                  <ul className="subTabs">
                    <li
                      className={getClassName({
                        planSubTab: true,
                        selected: this.state.subtab === SUBTAB_FREEFORM
                      })}
                      onClick={this.handleSubTab(SUBTAB_FREEFORM)}
                    >
                      Free Form
                    </li>
                    <li
                      className={getClassName({
                        planSubTab: true,
                        selected: this.state.subtab === SUBTAB_VALIDATION
                      })}
                      onClick={this.handleSubTab(SUBTAB_VALIDATION)}
                    >
                      Validation
                    </li>
                    <li
                      className={getClassName({
                        planSubTab: true,
                        selected: this.state.subtab === SUBTAB_NOTES
                      })}
                      onClick={this.handleSubTab(SUBTAB_NOTES)}
                    >
                      Notes
                    </li>
                  </ul>

                  <div className="subTabContent">
                    <table className={'subTabTable'}>
                      <thead>
                        <tr>
                          <th>Field Name</th>
                          <th
                            colSpan={this.typeCount() === 0 ? 1 : 2}
                            className={'actionHeader'}
                          >
                            <button
                              className="CTA"
                              disabled={this.typeCount() >= 40}
                              style={{
                                cursor: this.typeCount() >= 40 ? 'default' : 'pointer'
                              }}
                              onClick={this.handleOpenModal}
                            >
                              Add New Field
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.compact(_.get(this.props, 'customFields', [])).map(
                          customField => {
                            if (
                              customField.field_type !== TYPE_MAPPING[this.state.subtab]
                            ) {
                              return null;
                            }
                            recordsListed++;
                            return (
                              <tr key={customField.id}>
                                <td>{customField.label}</td>
                                <td className={'actionHalf edit'}>
                                  <button onClick={this.editCustomField(customField.id)}>
                                    <SvgEdit className={'tableIcon'} />
                                  </button>
                                </td>
                                <td className={'actionHalf delete'}>
                                  <button onClick={this.showDelete(customField.id)}>
                                    <SvgDelete className={'tableIcon'} />
                                  </button>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                    <>
                      {recordsListed === 0 ? (
                        <div className="belowContent">
                          <div>
                            You don&apos;t have any {TYPE_NAMES[this.state.subtab]} Custom
                            Fields.
                          </div>
                          <div>You can create up to 40.</div>
                        </div>
                      ) : null}
                    </>

                    {
                      {
                        [SUBTAB_FREEFORM]: (
                          <>
                            {this.state.showModal ? (
                              <BenefitsModal
                                showModal={this.state.showModal}
                                handleCloseModal={this.handleCloseModal}
                                title="Add New Field"
                                showX={true}
                              >
                                <p>
                                  <strong>NOTE: </strong>
                                  Configurations made at the health plan level will
                                  cascade down to all sub plans.{' '}
                                </p>
                                <form
                                  className={'modalForm'}
                                  onSubmit={e => e.preventDefault()}
                                >
                                  <div
                                    className="formElement"
                                    style={{ height: '150px' }}
                                  >
                                    <label className="customFieldInputLabel">
                                      Field Name
                                    </label>
                                    <input
                                      type="text"
                                      className="labelInput"
                                      value={_.get(this.state, 'formData.label', '')}
                                      onChange={this.formUpdate('label')}
                                    />
                                  </div>
                                </form>
                                <div className="buttons footer">
                                  <button
                                    className="exit"
                                    onClick={this.handleCloseModal}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="CTA"
                                    onClick={this.handleSubmitModal}
                                    disabled={this.state.formValid === false}
                                  >
                                    Save
                                  </button>
                                </div>
                              </BenefitsModal>
                            ) : null}
                          </>
                        ),
                        [SUBTAB_VALIDATION]: (
                          <>
                            {this.state.showModal ? (
                              <BenefitsModal
                                showModal={this.state.showModal}
                                handleCloseModal={this.handleCloseModal}
                                title="Add New Field"
                                showX={true}
                              >
                                <p>
                                  <strong>NOTE: </strong>
                                  Configurations made at the health plan level will
                                  cascade down to all sub plans.{' '}
                                </p>
                                <form
                                  className={'modalForm'}
                                  onSubmit={e => e.preventDefault()}
                                >
                                  <div className="formElement">
                                    <label className="customFieldInputLabel">
                                      Field Name
                                    </label>
                                    <input
                                      type="text"
                                      className="labelInput"
                                      maxLength={30}
                                      value={_.get(this.state, 'formData.label', '')}
                                      onChange={this.formUpdate('label')}
                                    />
                                  </div>
                                  <div>&nbsp;</div>
                                  <div>
                                    <ul>
                                      {_.get(this.state, 'formData.selectors', []).map(
                                        (selector, selectorId) => {
                                          return (
                                            <li key={selectorId}>
                                              <div className={'formElement'}>
                                                <label className="customFieldInputLabel">
                                                  Field Option
                                                </label>
                                                <input
                                                  type="text"
                                                  className="labelInput"
                                                  maxLength={30}
                                                  onChange={this.formUpdate(
                                                    `selectors.${selectorId}`
                                                  )}
                                                  value={_.get(
                                                    this.state,
                                                    `formData.selectors.${selectorId}`,
                                                    ''
                                                  )}
                                                />{' '}
                                                <button
                                                  className={'optionsIcon'}
                                                  onClick={this.removeOption(selectorId)}
                                                >
                                                  <SvgDelete />
                                                </button>
                                              </div>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                  <div className="buttons">
                                    <button
                                      className={'primary'}
                                      onClick={this.addOption}
                                    >
                                      Add Option
                                    </button>
                                    <button
                                      className={'warning'}
                                      onClick={this.removeOption()}
                                    >
                                      Remove
                                    </button>
                                    <button
                                      className={'gray'}
                                      onClick={this.removeOption('all')}
                                    >
                                      Remove All
                                    </button>
                                  </div>
                                </form>
                                <div className="buttons footer">
                                  <button
                                    className="exit"
                                    onClick={this.handleCloseModal}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="CTA"
                                    onClick={this.handleSubmitModal}
                                    disabled={this.state.formValid === false}
                                  >
                                    Save
                                  </button>
                                </div>
                              </BenefitsModal>
                            ) : null}
                          </>
                        ),
                        [SUBTAB_NOTES]: (
                          <>
                            {this.state.showModal ? (
                              <BenefitsModal
                                showModal={this.state.showModal}
                                handleCloseModal={this.handleCloseModal}
                                title="Add New Field"
                                showX={true}
                              >
                                <p>
                                  <strong>NOTE: </strong>
                                  Configurations made at the health plan level will
                                  cascade down to all sub plans.{' '}
                                </p>
                                <form
                                  className={'modalForm'}
                                  onSubmit={e => e.preventDefault()}
                                >
                                  <div
                                    className="formElement"
                                    style={{ height: '150px' }}
                                  >
                                    <label className="customFieldInputLabel">
                                      Field Name
                                    </label>
                                    <input
                                      type="text"
                                      maxLength={30}
                                      className="labelInput"
                                      value={_.get(this.state, 'formData.label', '')}
                                      onChange={this.formUpdate('label')}
                                    />
                                  </div>
                                </form>
                                <div className="buttons footer">
                                  <button
                                    className="exit"
                                    onClick={this.handleCloseModal}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="CTA"
                                    onClick={this.handleSubmitModal}
                                    disabled={this.state.formValid === false}
                                  >
                                    Save
                                  </button>
                                </div>
                              </BenefitsModal>
                            ) : null}
                          </>
                        )
                      }[this.state.subtab]
                    }
                    <>
                      {this.state.showDelete !== false ? (
                        <BenefitsModal
                          showModal={this.state.showDelete !== false}
                          handleCloseModal={this.handleCloseDelete}
                          title="Are you sure you want to delete?"
                          showX={true}
                        >
                          <p>
                            <strong>NOTE: </strong>
                            Configurations made at the health plan level will cascade down
                            to all sub plans.{' '}
                          </p>
                          <form
                            className={'modalForm'}
                            onSubmit={e => e.preventDefault()}
                          >
                            <div className="formElement" style={{ height: '120px' }}>
                              All data stored in this field for your members will be
                              permanently deleted.
                            </div>
                          </form>
                          <div className="buttons">
                            <button className="exit" onClick={this.handleCloseDelete}>
                              Cancel
                            </button>
                            <button
                              className="CTA"
                              onClick={this.deleteCustomField(this.state.showDelete)}
                            >
                              Delete
                            </button>
                          </div>
                        </BenefitsModal>
                      ) : null}
                    </>
                  </div>
                </>
              ),
              [TAB_BENEFITS_OVERWRITE]: (
                <div className="subTabContent">
                  <table>
                    <thead>
                      <tr>
                        <th>Role Name</th>
                        <th>Active</th>
                        <th>
                          <div className="buttons">
                            <button
                              className="CTA"
                              onClick={this.handleSubmitBenefitsOverwrite}
                            >
                              Save
                            </button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.benefitsOverwrite.map((row, index) => {
                        return (
                          <tr key={row.id}>
                            <td>{row.roleName}</td>
                            <td>
                              <input
                                type="checkbox"
                                value={row.id}
                                checked={!!row.selected}
                                onChange={this.benefitsOverwriteRoleChange(index)}
                              />
                            </td>
                            <td></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ),
              [TAB_DEFAULT_CLAIM_SUBMISSIONS]: (
                <div className="subTabContent">
                  <table>
                    <thead>
                      <tr>
                        <th>Claim Submissions Type</th>
                        <th>Active</th>
                        <th>
                          <div className="buttons">
                            <button
                              className="CTA"
                              onClick={() => this.handleSubmitClaimSubmissionType()}
                            >
                              Save
                            </button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.claimSubmissionTypes &&
                        this.state.claimSubmissionTypes.map(c => (
                          <tr key={c.submission_type_id}>
                            <td>{c.submission_type_name}</td>
                            <td>
                              <input
                                type="checkbox"
                                value={c.submission_type_id}
                                checked={c.selected}
                                onChange={e =>
                                  this.claimSubmissionTypeChange(e.target.value)
                                }
                              />
                            </td>
                            <td></td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ),
              [TAB_BILLING]: (
                <BillingConfiguration
                  billingParameters={this.props.billingParameters}
                  formData={this.state.formData}
                  showModal={this.state.showModal}
                  formValid={this.state.formValid}
                  handleCloseModal={this.handleCloseModal}
                  handleInvoiceConfigParametersSubmitModal={
                    this.handleInvoiceConfigParametersSubmitModal
                  }
                  editBillingParameter={this.editBillingParameter}
                  updateBillingForm={this.updateBillingForm}
                />
              )
            }[this.state.tab]
          }
        </div>
      </div>
    );
  }
}

CustomFields.propTypes = {
  selectedPlan: PropTypes.object,
  isSuperAdmin: PropTypes.bool,
  getCustomFields: PropTypes.func,
  postCustomField: PropTypes.func,
  deleteCustomField: PropTypes.func,
  upsertInvoiceConfig: PropTypes.func,
  getInvoiceConfig: PropTypes.func,
  customFields: PropTypes.array,
  billingParameters: PropTypes.object,
  error: PropTypes.object,
  params: PropTypes.object,
  customFieldsLastLoad: PropTypes.number,
  billingParametersLastLoad: PropTypes.number
};

CustomFields.defaultProps = {
  selectedPlan: {},
  isSuperAdmin: false,
  getCustomFields: () => {},
  postCustomField: () => {},
  deleteCustomField: () => {},
  upsertInvoiceConfig: () => {},
  getInvoiceConfig: () => {},
  benefitsOverwriteLastLoad: null,
  customFieldsLastLoad: null,
  billingParametersLastLoad: null,
  benefitsOverwrite: null,
  customFields: null,
  billingParameters: null,
  error: {},
  params: {}
};

const mapStateToProps = state => ({
  user: state.user,
  benefitsOverwrite: state?.settings?.benefitsOverwrite ?? null,
  customFields: state?.settings?.customFields ?? null,
  billingParameters: state?.settings?.billingParameters ?? {
    periodicity: 'Semi-monthly',
    submissionDeadline: 31,
    reviewPeriod: 5
  },
  benefitsOverwriteLastLoad: state?.settings?.benefitsOverwriteLastLoad ?? null,
  customFieldsLastLoad: state?.settings?.customFieldsLastLoad ?? null,
  billingParametersLastLoad: state?.settings?.billingParametersLastLoad ?? null
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCustomFields: data => getCustomFields(data),
      postCustomField: data => postCustomField(data),
      deleteCustomField: data => deleteCustomField(data),
      upsertInvoiceConfig: data => upsertInvoiceConfig(data),
      getInvoiceConfig: data => getInvoiceConfig(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomFields);
