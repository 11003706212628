import React, { useMemo, useContext } from 'react';
import { Input, MultiSelectButton } from '@SRHealth/frontend-lib';
import { type AddressVenueModel, type AddressModel, addressVenueFactory } from '~/models';
import type { AddressFieldType } from '../Rides.types';
import type { AddressFormAddressType } from './AddressForm';
import { useAddressChange } from './Address.utils';
import useVenues from '~/hooks/useVenues';
import type { VenueRecord } from '~/types';
import { MapContext } from './RideBookingMap.context';

export type AddressFormVenuesProps = {
  type: AddressFieldType;
  address: AddressVenueModel;
  onAddressChange: (type: AddressFieldType, address: AddressModel) => void;
  /** Optional alternative address types available to the user. */
  addressTypes?: AddressFormAddressType[];
};

export default function AddressFormVenues({
  address,
  type,
  onAddressChange,
  addressTypes = []
}: AddressFormVenuesProps) {
  const handleAddressTypeChange = useAddressChange(address.type, type, onAddressChange);
  const [markers, markerActions] = useContext(MapContext);
  const [venues, venueOptions, genEntranceOptions] = useVenues();

  const entranceOptions = useMemo(() => {
    if (!address.venue) return [];

    const onHover = (entrance: string) => {
      const index = markers.findIndex(m => m.popupContent === entrance);

      markerActions.setMarkers(
        markers.map((m, i) => ({ ...m, preOpenedPopup: i === index }))
      );
    };

    return genEntranceOptions(address.venue, onHover);
  }, [address.venue, genEntranceOptions, markers.length]);

  const hasAddressTypes = addressTypes.length > 1;
  const activeAddressType = addressTypes.findIndex(t => t.value === address.type);

  const currentVenueOption = venueOptions.find(({ value }) => value === address.venue);
  const currentEntranceOption = entranceOptions.find(
    ({ value }) => value.id === address.id
  );

  const handleVenueAddressChange = (_id: number, val: string) => {
    if (!venues || !venues[val]) return;

    onAddressChange(type, addressVenueFactory({ type: 'venue', venue: val }));

    markerActions.setMarkers(
      venues[val].map(entrance => ({
        lat: Number(entrance.latitude),
        lng: Number(entrance.longitude),
        popupContent: entrance.name,
        preOpenedPopup: false,
        type
      }))
    );
  };

  const handleEntranceAddressChange = (_id: number, val: VenueRecord) => {
    const addressVenueModel = addressVenueFactory({
      type: 'venue',
      value: val.address,
      id: val.id,
      venue: address.venue,
      entrance: val.name,
      latitude: Number(val.latitude),
      longitude: Number(val.longitude)
    });

    onAddressChange(type, addressVenueModel);
  };

  return (
    <div className="flex flex-col w-full gap-[8px] min-w-0">
      <div className="flex flex-row no-wrap w-full items-center gap-[4px]">
        <div className="flex-1 min-w-0">
          <Input
            inputId={0}
            type="select"
            name={type}
            label={`${type === 'depart' ? 'Depart' : 'Arrive'} - Venue`}
            options={venueOptions}
            placeholder="Select Venue"
            onChange={handleVenueAddressChange}
            value={currentVenueOption?.value}
            error={!!Object.keys(address.ruleErrors).length}
            required
          />
        </div>
        {hasAddressTypes ? (
          <div className="flex items-center h-[40px]" style={{ paddingBottom: '16px' }}>
            <MultiSelectButton
              buttons={addressTypes}
              activeButton={activeAddressType}
              onClick={handleAddressTypeChange}
            />
          </div>
        ) : null}
      </div>
      {entranceOptions.length > 0 ? (
        <Input
          style={{ maxWidth: '376px' }}
          inputId={0}
          type="select"
          name={type}
          options={entranceOptions}
          placeholder="Select One"
          onChange={handleEntranceAddressChange}
          value={currentEntranceOption?.value}
          error={!!Object.keys(address.ruleErrors).length}
          required
        />
      ) : null}
    </div>
  );
}
