import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash-es';
import moment from 'moment-timezone';
import { parse } from 'querystring';
import ReactLoading from 'react-loading';
import { IMPORTER, IMPORTER_NOTIFICATION_TYPE } from '~/constants';
import { getClassName } from '~/utilities/helperFunctions';
import PlanInfo from '../PlanInfo/PlanInfo';
import PlanBenefits from '../PlanBenefits/PlanBenefits';
import PlanBenefitCategories from '../PlanBenefitCategories/PlanBenefitCategories';
import PlanTreatments from '../PlanTreatments/PlanTreatments';
import PlanLocations from '../PlanLocations/PlanLocations';
import PlanRates from '../PlanRates/PlanRates';
import PlanRestrictions from '../PlanRestrictions';
import PlanCustomFields from '../PlanCustomFields';
import PlanMobilityTypes from '../PlanMobilityTypes/PlanMobilityTypes';
import { getPlan } from '~/Modules/plans';
import { getInvoiceConfig } from '~/Modules/settings';

const TABS = {
  BENEFITS: 'benefits',
  BENEFIT_CATEGORIES: 'categories',
  TREATMENTS: 'treatments',
  LOCATIONS: 'locations',
  RESTRICTIONS: 'restrictions',
  CUSTOM_FIELDS: 'custom fields',
  MOBILITY_TYPES: 'mobility types'
};

class PlanDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedPlanId: null,
      selectedTab: TABS.BENEFITS
    };
  }

  componentDidMount() {
    const newState = {};

    const query = parse(window.location.search.substr(1));
    if (query.tab) {
      newState.selectedTab = query.Tab;
    }

    if (this.props.selectedPlan.id) {
      newState.loadedPlanId = this.props.selectedPlan.id;
    }

    if (Object.keys(newState).length) {
      this.setState(newState, () => {
        if (newState.loadedPlanId) {
          this.props.getInvoiceConfig({
            healthPlanId: this.props.user.healthPlan.id,
            healthSubPlanId: newState.loadedPlanId
          });

          this.props.getPlan(newState.loadedPlanId);
        }
      });
    }
  }

  componentDidUpdate() {
    const newState = {};

    const query = parse(window.location.search.substr(1));
    // check if tab requested and current tab doesn't match
    if (query.tab && query.tab !== this.state.selectedTab) {
      newState.selectedTab = query.tab;
    } else if (!query.tab && this.state.selectedTab !== TABS.BENEFITS) {
      // set to default if no tab requested and not set to default already
      newState.selectedTab = TABS.BENEFITS;
    }

    if (this.props.selectedPlan.id && !this.state.loadedPlanId) {
      newState.loadedPlanId = this.props.selectedPlan.id;
    }

    if (Object.keys(newState).length) {
      this.setState(newState, () => {
        if (newState.loadedPlanId) {
          this.props.getInvoiceConfig({
            healthPlanId: this.props.user.healthPlan.id,
            healthSubPlanId: newState.loadedPlanId
          });

          this.props.getPlan(newState.loadedPlanId);
        }
      });
    }
  }

  /**
   * Handle tab selection
   * @param   {String}    selectedTab Selected tab as TABS constant
   * @returns {undefined}
   */
  handleTabSelection(selectedTab) {
    const selectedId = this.props.selectedPlan.id;
    if (selectedTab === TABS.BENEFITS) {
      // benefits is the implied default tab
      this.props.history.push(`/plans/${selectedId}`);
    } else {
      this.props.history.push(`/plans/${selectedId}?tab=${selectedTab}`);
    }
  }

  /**
   * Go to importer
   * @param {String} importerType Type of importer requested
   * @returns {undefined}
   */
  gotoImport(importerType) {
    if (this.props.isSuperAdmin) {
      this.props.history.push(
        `/import/${importerType}?subPlanId=${this.props.selectedPlan.id}`
      );
    }
  }

  /**
   * Gets localized Moment Date object based on user's timezone
   * @param {String} dateString Raw datetime string
   * @returns {Object} Moment object localized for user's timezone
   */
  getLocalizedDate(dateString) {
    const rawDate = moment(dateString, 'YYYY-MM-DD HH:mm:ss');
    const timezone = _.get(this.props, 'user.userData.timezone_format', '');
    return rawDate.utc().tz(timezone);
  }

  /**
   * Get Notification for a given tab
   * @param {String} type Tab type
   * @returns {Object} Notification object
   */
  getTabNotification(type) {
    const { selectedPlan } = this.props;
    let notification = { type: IMPORTER_NOTIFICATION_TYPE.NONE, message: '' };

    switch (type) {
      case 'treatment': {
        if (selectedPlan.treatmentCount === 0) {
          notification = {
            type: IMPORTER_NOTIFICATION_TYPE.ALERT,
            message: '0 Treatments Uploaded'
          };
        } else if (selectedPlan.meta && selectedPlan.meta.treatments.last.processing) {
          notification = {
            type: IMPORTER_NOTIFICATION_TYPE.WARN,
            message: 'Upload In Progress'
          };
        } else if (selectedPlan.meta && selectedPlan.meta.treatments.last.pending) {
          notification = {
            type: IMPORTER_NOTIFICATION_TYPE.WARN,
            message: 'Upload In Queue'
          };
        } else if (selectedPlan.meta && selectedPlan.meta.treatments.last.completed) {
          const updatedAt = this.getLocalizedDate(
            selectedPlan.meta.treatments.last.completed.updated_at
          );
          notification = {
            type: IMPORTER_NOTIFICATION_TYPE.INFO,
            message: `Last uploaded ${updatedAt.format('M/D/YYYY')}`
          };
        }
        break;
      }
      case 'location': {
        if (selectedPlan.locationCount === 0) {
          notification = {
            type: IMPORTER_NOTIFICATION_TYPE.ALERT,
            message: '0 Locations Uploaded'
          };
        } else if (selectedPlan.meta && selectedPlan.meta.locations.last.processing) {
          notification = {
            type: IMPORTER_NOTIFICATION_TYPE.WARN,
            message: 'Upload In Progress'
          };
        } else if (selectedPlan.meta && selectedPlan.meta.locations.last.pending) {
          notification = {
            type: IMPORTER_NOTIFICATION_TYPE.WARN,
            message: 'Upload In Queue'
          };
        } else if (selectedPlan.meta && selectedPlan.meta.locations.last.completed) {
          const updatedAt = this.getLocalizedDate(
            selectedPlan.meta.locations.last.completed.updated_at
          );
          notification = {
            type: IMPORTER_NOTIFICATION_TYPE.INFO,
            message: `Last uploaded ${updatedAt.format('M/D/YYYY')}`
          };
        }
        break;
      }
      default: {
        //
      }
    }

    return notification;
  }

  render() {
    const {
      user,
      selectedPlan,
      showList,
      isSuperAdmin,
      availableBenefitCategories,
      getBenefits,
      updatePlan,
      createBenefit,
      updateBenefit,
      deleteBenefit,
      getBenefitCategoryList,
      getBenefitCategories,
      createBenefitCategory,
      updateBenefitCategory,
      deleteBenefitCategory,
      getTreatmentsByPage,
      getLocationsByPage,
      getRestrictionsByPage,
      getCustomFields,
      billingParameters,
      billingParametersLastLoad
    } = this.props;
    const { selectedTab } = this.state;
    const treatmentsTabNotification = this.getTabNotification('treatment');
    const locationsTabNotification = this.getTabNotification('location');

    return (
      <div className="PlanDetail">
        {!selectedPlan.health_plan_id ? (
          <div className="innerWrapper">
            <ReactLoading type="spin" className="planLoading" />
          </div>
        ) : (
          <div className="innerWrapper">
            <PlanInfo
              selectedPlan={selectedPlan}
              showList={showList}
              updatePlan={updatePlan}
              isSuperAdmin={isSuperAdmin}
              healthPlan={user.healthPlan}
              billingParameters={billingParameters}
              billingParametersLastLoad={billingParametersLastLoad}
            />
            <div className="tabsWrapper">
              <ul className="topBlueNav">
                <li
                  className={getClassName({
                    planTab: true,
                    selected: selectedTab === TABS.BENEFITS
                  })}
                  onClick={() => this.handleTabSelection(TABS.BENEFITS)}
                >
                  Benefits
                </li>
                <li
                  className={getClassName({
                    planTab: true,
                    selected: selectedTab === TABS.BENEFIT_CATEGORIES
                  })}
                  onClick={() => this.handleTabSelection(TABS.BENEFIT_CATEGORIES)}
                >
                  Benefit Categories
                </li>
                <li
                  className={getClassName({
                    planTab: true,
                    selected: selectedTab === TABS.TREATMENTS
                  })}
                  onClick={() => this.handleTabSelection(TABS.TREATMENTS)}
                >
                  Treatments
                  {treatmentsTabNotification.type !== IMPORTER_NOTIFICATION_TYPE.NONE ? (
                    <div className="notificationWrapper">
                      <div
                        className={['notification', treatmentsTabNotification.type].join(
                          ' '
                        )}
                      >
                        {treatmentsTabNotification.message}
                      </div>
                    </div>
                  ) : null}
                </li>
                <li
                  className={getClassName({
                    planTab: true,
                    selected: selectedTab === TABS.LOCATIONS
                  })}
                  onClick={() => this.handleTabSelection(TABS.LOCATIONS)}
                >
                  Locations
                  {locationsTabNotification.type !== IMPORTER_NOTIFICATION_TYPE.NONE ? (
                    <div className="notificationWrapper">
                      <div
                        className={['notification', locationsTabNotification.type].join(
                          ' '
                        )}
                      >
                        {locationsTabNotification.message}
                      </div>
                    </div>
                  ) : null}
                </li>
                <li
                  className={getClassName({
                    planTab: true,
                    selected: selectedTab === TABS.RESTRICTIONS
                  })}
                  onClick={() => this.handleTabSelection(TABS.RESTRICTIONS)}
                >
                  Access Restrictions
                </li>
                <li
                  className={getClassName({
                    planTab: true,
                    selected: selectedTab === TABS.CUSTOM_FIELDS
                  })}
                  onClick={() => this.handleTabSelection(TABS.CUSTOM_FIELDS)}
                >
                  Custom Fields
                </li>
                <li
                  className={getClassName({
                    planTab: true,
                    selected: selectedTab === TABS.MOBILITY_TYPES
                  })}
                  onClick={() => this.handleTabSelection(TABS.MOBILITY_TYPES)}
                >
                  Mobility Types
                </li>
              </ul>
              <div className="tabContent">
                {
                  {
                    [TABS.BENEFITS]: (
                      <PlanBenefits
                        selectedPlan={selectedPlan}
                        isSuperAdmin={isSuperAdmin}
                        getBenefits={getBenefits}
                        createBenefit={data => createBenefit(data)}
                        updateBenefit={data => updateBenefit(data)}
                        deleteBenefit={data => deleteBenefit(data)}
                      />
                    ),
                    [TABS.BENEFIT_CATEGORIES]: (
                      <PlanBenefitCategories
                        selectedPlan={selectedPlan}
                        isSuperAdmin={isSuperAdmin}
                        availableBenefitCategories={availableBenefitCategories}
                        getBenefitCategoryList={getBenefitCategoryList}
                        getBenefitCategories={getBenefitCategories}
                        createBenefit={data => createBenefitCategory(data)}
                        updateBenefit={data => updateBenefitCategory(data)}
                        deleteBenefit={data => deleteBenefitCategory(data)}
                      />
                    ),
                    [TABS.TREATMENTS]: (
                      <PlanTreatments
                        selectedPlan={selectedPlan}
                        isSuperAdmin={isSuperAdmin}
                        getTreatmentsByPage={getTreatmentsByPage}
                        gotoImport={() => this.gotoImport(IMPORTER.TREATMENT)}
                      />
                    ),
                    [TABS.LOCATIONS]: (
                      <PlanLocations
                        selectedPlan={selectedPlan}
                        isSuperAdmin={isSuperAdmin}
                        getLocationsByPage={getLocationsByPage}
                        gotoImport={() => this.gotoImport(IMPORTER.LOCATION)}
                      />
                    ),
                    [TABS.RATES]: <PlanRates />,
                    [TABS.RESTRICTIONS]: (
                      <PlanRestrictions
                        selectedPlan={selectedPlan}
                        getRestrictionsByPage={getRestrictionsByPage}
                        isSuperAdmin={isSuperAdmin}
                        gotoImport={() => this.gotoImport(IMPORTER.LOCATION)}
                      />
                    ),
                    [TABS.CUSTOM_FIELDS]: (
                      <PlanCustomFields
                        selectedPlan={selectedPlan}
                        isSuperAdmin={isSuperAdmin}
                        getCustomFields={getCustomFields}
                      />
                    ),
                    [TABS.MOBILITY_TYPES]: (
                      <PlanMobilityTypes selectedPlan={selectedPlan} />
                    )
                  }[selectedTab]
                }
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PlanDetail.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  selectedPlan: PropTypes.object,
  showList: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
  availableBenefitCategories: PropTypes.array,
  updatePlan: PropTypes.func,
  getBenefits: PropTypes.func,
  createBenefit: PropTypes.func,
  updateBenefit: PropTypes.func,
  deleteBenefit: PropTypes.func,
  getBenefitCategoryList: PropTypes.func,
  getBenefitCategories: PropTypes.func,
  createBenefitCategory: PropTypes.func,
  updateBenefitCategory: PropTypes.func,
  deleteBenefitCategory: PropTypes.func,
  getTreatmentsByPage: PropTypes.func,
  getLocationsByPage: PropTypes.func,
  getRestrictionsByPage: PropTypes.func,
  getPlan: PropTypes.func,
  getInvoiceConfig: PropTypes.func,
  billingParameters: PropTypes.object,
  billingParametersLastLoad: PropTypes.number
};

PlanDetail.defaultProps = {
  user: {},
  history: {},
  selectedPlan: {},
  showList: () => {},
  isSuperAdmin: false,
  availableBenefitCategories: [],
  updatePlan: () => {},
  getBenefits: () => {},
  createBenefit: () => {},
  updateBenefit: () => {},
  deleteBenefit: () => {},
  getBenefitCategoryList: () => {},
  getBenefitCategories: () => {},
  createBenefitCategory: () => {},
  updateBenefitCategory: () => {},
  deleteBenefitCategory: () => {},
  getTreatmentsByPage: () => {},
  getLocationsByPage: () => {},
  getRestrictionsByPage: () => {},
  getPlan: () => {},
  getInvoiceConfig: () => {},
  billingParameters: {},
  billingParametersLastLoad: null
};

const mapStateToProps = state => ({
  billingParameters: state.settings.billingParameters ?? {
    default: true,
    periodicity: 'Semi-monthly',
    submissionDeadline: 31,
    reviewPeriod: 5
  },
  billingParametersLastLoad: state.settings.billingParametersLastLoad ?? null
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPlan: data => getPlan(data),
      getInvoiceConfig: data => getInvoiceConfig(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PlanDetail);
