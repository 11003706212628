import { token } from '~/utilities/auth.helper';
import type { App } from 'contracts/mod';

const BILLING_URL_V1 = `${process.env.APP_LAYER_URL}/billing/v1` as const;

/**
 * Retrieves the current open adjustment for this ride if applicable
 * @param {number} rideId
 * @returns {SearchAdjustmentsResponse}
 */
export async function getRideAdjustment(rideId: number) {
  return fetch(`${BILLING_URL_V1}/rides/${rideId}/adjustments?terminal=false`, {
    headers: { Authorization: `Bearer ${token()}` }
  }).then(
    res =>
      res.json() as Promise<App.Billing.V1.Rides.Adjustments.SearchRideAdjustmentsResponse>
  );
}

/**
 * Submits a new ride adjustment. This will create a new adjustment
 * TODO finish this comment and annotate input types
 */
export async function submitRideAdjustment(
  rideId,
  subTypeId,
  requestingPartyId,
  notes,
  requestedAmount
) {
  const body: App.Billing.V1.Rides.Adjustments.CreateRideAdjustmentRequest = {
    referenceType: 'rides',
    subTypeId,
    requestingPartyId,
    notes,
    requestedAmount
  };
  return fetch(`${BILLING_URL_V1}/rides/${rideId}`, {
    method: 'post',
    headers: { Authorization: `Bearer ${token()}` },
    body: JSON.stringify(body)
  }).then(
    res =>
      res.json() as Promise<App.Billing.V1.Rides.Adjustments.CreateRideAdjustmentResponse>
  );
}
