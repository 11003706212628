import moment from 'moment';

export const DATE_FORMAT = 'MMM DD, YYYY';
export const DATE_FORMAT_INPUT = 'MM/DD/YYYY';
export const DATE_OF_BIRTH_DB_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'hh:mm a';
/** Timestamp format that includes timezone */
export const TIME_FORMAT_TIMEZONE = 'h:mm A z';
export const DATE_API_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TEXT_FORMAT = 'ddd, MMM D';
export const DATE_TIME_FORMAT = `${DATE_FORMAT_INPUT} ${TIME_FORMAT}`;
export const MONTHS_FORMAT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export const REGEX_DATE =
  /^((0?[123456789]|10|11|12)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[01]?))(-|\/)((19)([1-9])(\d{1})|(20)([0123456789])(\d{1})|([89021])(\d{1}))|(0?[2469]|11)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[0]?))(-|\/)((19)([2-9])(\d{1})|(20)([01])(\d{1})|([8901])(\d{1})))$/gm;
export const REGEX_PHONE_NUMBER = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const REGEX_LAT_LNG =
  /^([-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)),\s*([-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?))$/;
// 'hh:mm a' time format regex
export const REGEX_TIME = /^(1[0-2]|0?[1-9]):([0-5][0-9])( [ap]m)$/;

//default, last time period, ie last 30 days or so
export const BEGIN_DATE = moment().subtract(1, 'months').toDate();
export const END_DATE = moment().toDate();

//today
export const DAY_START = moment().startOf('day').toDate();
export const DAY_END = moment().endOf('day').toDate();
export const NOW = moment().toDate();

//yesterday
export const YESTERDAY_START = moment().subtract(1, 'day').startOf('day').toDate();
export const YESTERDAY_END = moment().subtract(1, 'day').endOf('day').toDate();

//last 7 days
export const LAST_7_START = moment().subtract(7, 'day').toDate();
export const LAST_7_END = END_DATE;

// last 14 days
export const LAST_14_START = moment().subtract(14, 'day').toDate();

//next 2 days
export const TOMORROW_END = moment().add(1, 'day').endOf('day').toDate();

//next 2 days
export const NEXT_2_END = moment().add(2, 'day').endOf('day').toDate();

//last 30 days
export const LAST_30_START = moment().subtract(30, 'day').toDate();
export const LAST_30_END = END_DATE;

//this month
export const MONTH_START = moment().startOf('month').toDate();
export const MONTH_END = moment().endOf('month').toDate();

//last month
export const LAST_MONTH_START = moment().subtract(1, 'month').startOf('month').toDate();
export const LAST_MONTH_END = moment().subtract(1, 'month').endOf('month').toDate();

// last year
export const LAST_MONTH = moment().subtract(1, 'month').toDate();
export const LAST_YEAR = moment().subtract(1, 'year').toDate();
export const YEAR_START = moment().startOf('year').toDate();

export const DATE_RANGES = [
  {
    value: 'today',
    startDate: DAY_START,
    endDate: DAY_END,
    label: 'Today'
  },
  {
    value: 'yesterday',
    startDate: YESTERDAY_START,
    endDate: YESTERDAY_END,
    label: 'Yesterday'
  },
  {
    value: 'last7Days',
    startDate: LAST_7_START,
    endDate: LAST_7_END,
    label: 'Last 7 days'
  },
  {
    value: 'last30days',
    startDate: LAST_30_START,
    endDate: LAST_30_END,
    label: 'Last 30 days'
  },
  {
    value: 'thisMonth',
    startDate: MONTH_START,
    endDate: MONTH_END,
    label: 'This Month'
  },
  {
    value: 'lastMonth',
    startDate: LAST_MONTH_START,
    endDate: LAST_MONTH_END,
    label: 'Last Month'
  }
];

export const DEFAULT_RIDE_FIELDS = {
  limit: 50,
  start: 0,
  fromDate: DAY_START,
  toDate: DAY_END,
  orderType: 'desc',
  orderBy: 'pickupDate'
};

export const DISABLE_EDIT_STATUS = ['Arrived', 'Inbound', 'Started'];

export const EXPENSE_ID_MAX_LENGTH = 24;

export const LARAVEL_APP_URL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API}`;
export const LUMEN_API_BASE_URL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ANALYTICS_API_HOST}`;

export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
export const PUSHER_APP_ID = process.env.REACT_APP_PUSHER_APP_ID;
export const PUSHER_SECRET = process.env.REACT_APP_PUSHER_SECRET;
export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
export const ANALYTICS_API = `//${process.env.REACT_APP_ANALYTICS_API_HOST}`;

export const NEW_PATIENT_MESSAGE =
  'This member does not exist in your network yet, do you want to add them by requesting this ride?';
export const REPEAT_APPONTMENT_TEXT = 'Repeat Appointments';
export const RECURRING_RIDES_LYFT =
  'Sorry, Recurring ride booking not supported for Lyft.';
export const LYFT_RETRY_MESSAGE =
  'Sorry, we could not find any Lyft drivers for your request at this time. To increase the chances of finding a driver, we recommend waiting at least 5 minutes before clicking ‘Retry Request’.';
export const RIDESHARE_RETRY_MESSAGE =
  'Sorry, we could not find any drivers for your request at this time. To increase the chances of finding a driver, we recommend waiting at least 5 minutes before clicking ‘Retry Request’.';
export const INVALID_ADDRESS_MESSAGE =
  'Invalid address. Please select from suggested addresses.';
export const NEW_PATIENT_RESTRICT_PURCHASE_ISSUE_MESSAGE =
  'Sorry we can\'t book this ride, the member is not in your network or lacks funds, please contact your admin';
export const NEW_PATIENT_RESTRICT_BOOKING_MESSAGE =
  'Sorry we can\'t book this ride, the member is not in your network, please contact your admin';
export const PATIENT_INELIGIBLE_MESSAGE =
  'Sorry we can\'t book this ride, the member is not eligible to book rides, please contact your admin';
export const NEW_PATIENT_HEALTH_PLAN_REQUEST =
  'Passenger does not exist for the Health Plan. Please try Self Funded or another Health Plan.';
export const RIDE_HAS_BEEN_SCHEDULED_MESSAGE = 'Ride has been scheduled';
export const RIDE_CONVERTED_TO_WILL_CALL_MESSAGE =
  'Ride has been successfully converted to WillCall';

export const RIDE_CARDS_LIMIT = 20;
export const LYFT_VEHICLE_ID = 8;
export const UBER_VEHICLE_ID = 40;
export const NEMT_AMBULATORY_ID = 5;
export const CANCELLATION_REASONS = {
  '1': 'Patient was a no-show',
  '2': 'Driver was a no-show',
  '3': 'Patient had alternate transportation',
  '4': 'Driver unable to find location',
  '5': 'Other',
  '6': 'Patient was a no-show',
  '7': 'Driver was a no-show',
  '8': 'Patient had alternate transportation',
  '9': 'Driver unable to find location',
  '10': 'Other',
  '11': 'Request Missed By Driver',
  '12': 'First Leg of Ride Cancelled',
  '13': 'Unable to fulfill',
  '14': 'Unable to fulfill the Ride'
};
export const CHANNEL_PREFIX_RIDES = 'private-rides-hospital';
export const CHANNEL_PREFIX_DRIVERS = 'private-drivers-hospital';
export const CHANNEL_PREFIX_CHATS = 'private-chats-hospital';
export const CHANNEL_PREFIX_CHAT_REQUEST = 'private-chats-request';
export const CHANNEL_PREFIX_USER = 'private-user';
export const CHANNEL_EVENT_TYPE = {
  NEW_CHAT_REQUEST: 'newChatRequest',
  ANSWER_CHAT_REQUEST: 'answerChatRequest',
  CLAIM_CHAT_REQUEST: 'claimChatRequest',
  BOOK_CHAT_REQUEST: 'bookChatRequest',
  READ_CHAT_REQUEST: 'readChatRequest',
  UPDATE_CHAT_REQUEST: 'updateChatRequest',
  CANCEL_CHAT_REQUEST: 'cancelChatRequest',
  REASSIGN_CHAT_REQUEST: 'reassignChatRequest',
  NEW_CHAT_MESSAGE: 'newChatMessage',
  USER_LOGOUT: 'userLogout'
};
export const CHAT_CANCELLATION_REASON = {
  BY_CARE_PROVIDER: 1,
  BY_NEMT_RESPONDER: 2
};
export const ACTIVE_RIDES = ['Inbound', 'Arrived', 'Started'];

export const ACTIVE_SCHEDULED_COMPLETED = [
  'Inbound',
  'Arrived',
  'Started',
  'Scheduled',
  'Completed'
];

export const CANCELLED_RIDES = [
  'DriverCancelled',
  'Cancelled',
  'DriverRejected',
  'Rejected',
  'RequestMissed'
];

export const COMPLETED_OR_CANCELLED = [...CANCELLED_RIDES, 'Completed'];

export const MULTIPLE_LEG_LIMIT = 5;

export const IMPORTER = {
  NONE: 'none',
  TREATMENT: 'treatment',
  LOCATION: 'location',
  MEMBER: 'member',
  EXPENSE: 'expense',
  RIDESHARE: 'rideshare',
  PUBLICTRANSIT: 'publictransit'
};
export const IMPORTER_STEP = {
  NONE: 0,
  UPLOAD: 1,
  VERIFY: 2,
  PREVIEW: 3,
  CONFIRM: 4
};
export const RESTRICTION_TYPE = {
  NONE: {
    id: 0,
    label: 'Select Benefit Type',
    unit: '',
    field: ''
  },
  TO_CARE_MILES: {
    id: 1,
    label: 'To Care: Miles per ride',
    unit: 'miles',
    field: 'to_care_miles_per_ride'
  },
  FROM_CARE_MILES: {
    id: 2,
    label: 'From Care: Miles per ride',
    unit: 'miles',
    field: 'from_care_miles_per_ride'
  },
  RIDES_PER_YEAR: {
    id: 3,
    label: 'Rides per year',
    unit: 'rides',
    field: 'rides_per_year'
  },
  COST_PER_YEAR: {
    id: 4,
    label: 'Cost per year',
    unit: 'dollars',
    field: 'cost_per_year'
  },
  RIDES_PER_MONTH: {
    id: 5,
    label: 'Rides per month',
    unit: 'rides',
    field: 'rides_per_month'
  }
};
export const RESTRICTION_TYPE_FOR_CATEGORIES = {
  NONE: {
    id: 0,
    label: 'Select Benefit Type',
    unit: '',
    field: ''
  },
  RIDES_PER_YEAR: {
    id: 3,
    label: 'Rides per year',
    unit: 'rides',
    field: 'rides_per_year'
  }
};
export const IMPORTER_NOTIFICATION_TYPE = {
  NONE: 'none',
  INFO: 'info',
  WARN: 'warn',
  ALERT: 'alert'
};
export const ACCEPTABLE_MODES = {
  SEDAN: {
    value: 'SDN',
    label: 'Sedan'
  },
  WHEELCHAIR_VAN: {
    value: 'WCV',
    label: 'Wheelchair Van'
  }
};
export const SERVICE_LEVELS = {
  CURB_TO_CURB: {
    value: 'c2c',
    label: 'Curb to Curb'
  },
  DOOR_TO_DOOR: {
    value: 'd2d',
    label: 'Door to Door'
  },
  HAND_TO_HAND: {
    value: 'h2h',
    label: 'Hand to Hand'
  }
};
export const DRIVER_LANGUAGES = {
  ENGLISH_CA: {
    value: 'en-CA',
    label: 'English (CA)'
  },
  ENGLISH_US: {
    value: 'en-US',
    label: 'English (US)'
  },
  FRENCH_CA: {
    value: 'fr-CA',
    label: 'French (CA)'
  },
  SPANISH_US: {
    value: 'es-US',
    label: 'Spanish (US)'
  }
};
export const DRIVER_SKILLS = {
  CPR: {
    value: 'CPR',
    label: 'CPR'
  },
  BLS: {
    value: 'BLS',
    label: 'BLS'
  }
};
export const VEHICLE_CAPABILITIES = {
  LIFT: {
    value: 'lift',
    label: 'Lift'
  },
  HIGH_DOOR: {
    value: 'highdoor',
    label: 'High Door'
  }
};

export const LYFT_ERROR_MESSAGE = 'No vehicles are currently available.';
export const NEMT_ERROR_MESSAGE = 'No Transport Companies Available';
export const PUBLIC_ROUTELESS_RIDES_MESSAGE = `Unfortunately, we could not find any public transit routes to display for the selected date and time. You may proceed without routes by clicking "Continue Without Routes" below, update the date of your ride and try again, or cancel the request.`;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d\s]).{10,}$/;
export const EMAIL_REGEX =
  /^^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gim;

export const COMPONENT_STRINGS = {
  BookingConfirmation: {
    rideBookedConfirmation: 'Ride has been booked.',
    headingTransportOption: 'Select Transport',
    headingRideConfirmation: 'Ride Confirmation'
  },
  RideNotes: {
    defaultTransportTypeLabel: 'Transport Type',
    defaultTransportSubTypeLabel: 'Sub Mobility Type',
    rideNotesPlaceholder:
      'Ride Notes; e.g. Apt/Unit/Suite #, Pickup at Main Entrance, Drop off at side street',
    rideNoteLabel: 'Ride Notes',
    repeatRideLabel: 'Repeat Ride',
    editRideLabel: 'Edit Ride',
    requestRideLabel: 'CONTINUE',
    invalidInputLabel: (fieldName = 'This Input') => `${fieldName} is a required field.`,
    addAnotherTripLabel: 'Add Another Leg',
    payWithCardLabel: 'Pay With Passenger Credit Card'
  },
  ScheduledRides: {
    showLoadingText: 'Loading Rides...',
    actionRequiredModalBody:
      'No NEMT company connected to this facility. Please email SafeRide Support.',
    actionRequiredModalTitle: 'Action Required',
    actionRequiredModalButtonText: 'OK',
    willCallReadyNowErrorTitle: '⚠️Action Required⚠️',
    willCallReadyNowErrorText:
      '<div style="color: red; text-align: center;">You have successfully scheduled this Will Call ride but the Transportation Provider needs to be alerted! <br /> <br /> Please call the appropriate party to make sure the member gets picked up. </div>',
    willCallReadyNowErrorButtonText: 'OK',
    updateDateErrorTitle: 'Update Ride Notice',
    updateDateErrorButtonText: 'CANCEL'
  }
};
export const DAVITA_NETWORK_ID = 17;
export const METERS_TO_MILES_CONVERSION = 0.000621371;
export const MAX_HOSPITALS_PUSHER_AUTH = 85;
export const MEMBER_PROFILE_UPLOAD_LIMIT = 10;

export type PublicTransitRoutePreference = {
  name: string;
  text: string;
  values: string[];
};

export const PUBLIC_TRANSIT_ROUTE_PREFERENCES = [
  {
    name: 'BEST_ROUTE', //TODO: find the actual constant in the google directions api
    text: 'Best route',
    values: ['']
  },
  {
    name: 'LESS_WALKING', //this constant is confirmed
    text: 'Less walking',
    values: ['LESS_WALKING']
  },
  {
    name: 'FEWER_TRANSFERS', //this constant is confirmed
    text: 'Fewer transfers',
    values: ['FEWER_TRANSFERS']
  }
];

export type PublicTransitModePreference = {
  name: string;
  text: string;
  values: string[];
};

export const PUBLIC_TRANSIT_MODE_PREFERENCES = [
  {
    name: 'BUS',
    values: ['BUS'],
    text: 'Bus'
  },
  {
    name: 'SUBWAY',
    values: ['SUBWAY'],
    text: 'Subway'
  },
  {
    name: 'TRAIN',
    values: ['TRAIN'],
    text: 'Train'
  },
  {
    name: 'TRAM_AND_LIGHT_RAIL',
    values: ['TRAM', 'RAIL'], //TODO: add RAIL to this array if product requires it.
    text: 'Tram & light rail'
  }
];

/**
 * SF: Self Funded
 * SFHP: Self Funded and Health Plan
 * HP: Health Plan
 */
export const FUNDING_SOURCES = {
  SF: {
    key: 'SF',
    options: ['Self Funded'],
    disabled: true,
    label: 'Self Funded'
  },
  SFHP: {
    key: 'SFHP',
    options: ['Self Funded', 'Health Plan'],
    disabled: false,
    label: 'Select a Funding Source'
  },
  HP: {
    key: 'HP',
    options: ['Health Plan'],
    disabled: true,
    label: 'Health Plan'
  }
};

export const FrequencyEnum = Object.freeze({
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY'
});

export const DaysEnum = Object.freeze({
  MO: 'MO',
  TU: 'TU',
  WE: 'WE',
  TH: 'TH',
  FR: 'FR',
  SA: 'SA',
  SU: 'SU'
});

export const MonthlyRepeatENUM = Object.freeze({
  MONTHLY_BY_DAY: 'MONTHLY_BY_DAY',
  MONTHLY_BY_WEEK_NUMBER: 'MONTHLY_BY_WEEK_NUMBER'
});

export const EditRepeatingExpensesEnum = Object.freeze({
  THIS_EXPENSE: 'THIS_EXPENSE',
  THIS_AND_FOLLOWING: 'THIS_AND_FOLLOWING',
  ALL_EXPENSES: 'ALL_EXPENSES'
});

export const BOOKING_TYPES = Object.freeze({
  ON_DEMAND: 'ondemand'
});

export const RIDE_MODE = Object.freeze({
  PUBLIC: 'Public',
  PRIVATE: 'Private'
});

export const RIDESHARE_MOBILITY_ID_ARRAY = [8];

export const LAT_LNG_DISPLAY_PRECISION = 5;

export const PAGES = {
  RIDE_BOOKING_FLOW: 'RIDE_BOOKING_FLOW',
  MEMBER_PROFILE: 'MEMBER_PROFILE'
};

/**
 * Mapping conversion from first letter of week day
 * to integer
 */
export const DAY_CHAR_TO_INT = [
  {
    char: 'S',
    int: 0
  },
  {
    char: 'M',
    int: 1
  },
  {
    char: 'T',
    int: 2
  },
  {
    char: 'W',
    int: 3
  },
  {
    char: 'R',
    int: 4
  },
  {
    char: 'F',
    int: 5
  },
  {
    char: 'U',
    int: 6
  }
];

/**
 * Terminal Ride Statuses
 */
export const TERMINAL_RIDE_STATUSES = [
  'Completed',
  'Cancelled',
  'DriverCancelled',
  'Dispatcher Managed - Completed',
  'Dispatcher Managed - Cancelled'
];

/**
 * List of Schedule page paths where alerts are shown.
 */
export const ALERT_PAGES = ['alerts', 'ride-share-alerts', 'ride-nemt-alerts'];
