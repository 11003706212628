import { useMemo, useState } from 'react';
import type { MapMarker } from './RideBookingMap.context';

type MarkerActions = {
  addMarker: (marker: MapMarker) => void;
  removeMarker: (idx: number) => void;
  setMarkers: (markers: MapMarker[]) => void;
};

/** A hook to manage marker state and marker actions for manipulating the
 * RideBookingMap component. */
export default function useRideBookingMap(): [MapMarker[], MarkerActions] {
  /** State store for the map markers in the Rides section. */
  const [_markers, setMarkers] = useState<MapMarker[]>([]);

  /** Actions provided to the MapContext to manipulate the markers. */
  const markerActions = useMemo(
    () => ({
      addMarker: (marker: MapMarker) => setMarkers([..._markers, marker]),
      removeMarker: (idx: number) => setMarkers(_markers.toSpliced(idx, 1)),
      setMarkers: (markers: MapMarker[] | ((markers: MapMarker[]) => MapMarker[])) =>
        setMarkers(Array.isArray(markers) ? markers : markers(_markers))
    }),
    [_markers, setMarkers]
  );

  return [_markers, markerActions];
}
